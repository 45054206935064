export const GoogleForms = ({formNr = 1}) => {
  return (
    <>
      {formNr === 1 && // sing up
        <iframe
          id="googleFormIframe"
          src="https://docs.google.com/forms/d/e/1FAIpQLSd1-eIdqzNIOxcVEnEhsFlIKrlEvYdw1JizDtOzv3A4BlFjFw/viewform?embedded=true"
          width="640"
          height="600"
          style={{width: "100%"}}
        >
          Loading…
        </iframe>
      }
      {formNr === 2 && // contributor
        <iframe
          id="googleFormIframe"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfVsbZj0no73aJazQ9--7okA4i_l4ZKiIJfGouy5Uh_GuK5UA/viewform?embedded=true"
          width="640"
          height="600"
          style={{width: "100%"}}
        >
          Loading…
        </iframe>
      }
    </>
  );
}
