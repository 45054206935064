import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './footer4.css'

const Footer4 = (props) => {
  return (
    <footer className="footer4-footer7 thq-section-padding">
      <div className="footer4-max-width thq-section-max-width">
        <div className="footer4-content">
          <div className="footer4-logo1">
            <img
              alt={props.logoAlt}
              src={props.logoSrc}
              className="footer4-logo2"
            />
          </div>
        </div>
        <div className="footer4-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer4-row">
            <div className="footer4-container">
              <span className="thq-body-small">© 2024 SideByte</span>
            </div>
            <div className="footer4-footer-links">
              {props.privacyLink ?? (
                <Fragment>
                  <a className="footer4-text17 thq-body-small" target="_blank" href="/privacy-policy.md">
                    Privacy policy
                  </a>
                </Fragment>
              )}
              {props.cookiesLink ?? (
                <Fragment>
                  <a className="footer4-text15 thq-body-small" target="_blank" href="/cookie-policy.md">
                    Cookies policy
                  </a>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer4.defaultProps = {
  logoSrc: '/logo-slogan.svg',
  link1: undefined,
  cookiesLink: undefined,
  link3: undefined,
  privacyLink: undefined,
  logoAlt: 'Tattoo App Logo',
  link4: undefined,
  link2: undefined,
  link5: undefined,
  termsLink: undefined,
}

Footer4.propTypes = {
  logoSrc: PropTypes.string,
  link1: PropTypes.element,
  cookiesLink: PropTypes.element,
  link3: PropTypes.element,
  privacyLink: PropTypes.element,
  logoAlt: PropTypes.string,
  link4: PropTypes.element,
  link2: PropTypes.element,
  link5: PropTypes.element,
  termsLink: PropTypes.element,
}

export default Footer4
