import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = (props) => {
  return (
    <div className="steps2-container1 thq-section-padding" id="benefits">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container2 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="thq-heading-2">
              The benefits of our web app
            </h2>
            <p className="thq-body-large">
              With our web app, you can not only test your tattoo design on a 3D model.
              This tool allows you to precisely adjust the size, shape and position of the tattoo,
              which makes the final decision easier. What's more, you can easily share the finished
              preview with your client, allowing them to see the design before it is made.
              This makes the whole process more transparent and helps avoid misunderstandings.
            </p>
          </div>
          <div className="steps2-container3">
            <div className="steps2-container4 thq-card">
              {props.step1Title ?? (
                <Fragment>
                  <h2 className="steps2-text29 thq-heading-2 steps2-title">Create account</h2>
                </Fragment>
              )}
              {props.step1Description ?? (
                <Fragment>
                  <span className="steps2-text31 thq-body-small">
                    Register to access the web app and start enjoying the ability to apply a tattoo to a 3D model.
                  </span>
                </Fragment>
              )}
              <label className="steps2-text15 thq-heading-3">01</label>
            </div>
            <div className="steps2-container5 thq-card">
              {props.step2Title ?? (
                <Fragment>
                  <h2 className="steps2-text25 thq-heading-2 steps2-title">
                    3D Model Selection
                  </h2>
                </Fragment>
              )}
              {props.step2Description ?? (
                <Fragment>
                  <span className="steps2-text30 thq-body-small">
                    Choose one of several available human models, so the figure reflects the target body as closely as possible.
                  </span>
                </Fragment>
              )}
              <label className="steps2-text18 thq-heading-3">02</label>
            </div>
            <div className="steps2-container6 thq-card">
              {props.step3Title ?? (
                <Fragment>
                  <h2 className="steps2-text32 thq-heading-2 steps2-title">
                    Upload Tattoo Design
                  </h2>
                </Fragment>
              )}
              {props.step3Description ?? (
                <Fragment>
                  <span className="steps2-text28 thq-body-small">
                    Upload your tattoo design, then adjust its position and size to perfectly fit your chosen model.
                  </span>
                </Fragment>
              )}
              <label className="steps2-text21 thq-heading-3">03</label>
            </div>
            <div className="steps2-container7 thq-card">
              {props.step4Title ?? (
                <Fragment>
                  <h2 className="steps2-text26 thq-heading-2 steps2-title">
                    Admire the results
                  </h2>
                </Fragment>
              )}
              {props.step4Description ?? (
                <Fragment>
                  <span className="steps2-text27 thq-body-small">
                    See how your chosen tattoo looks on a 3D model or send a link to the project to your client.
                  </span>
                </Fragment>
              )}
              <label className="steps2-text24 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.defaultProps = {
  step2Title: undefined,
  step4Title: undefined,
  step4Description: undefined,
  step3Description: undefined,
  step1Title: undefined,
  step2Description: undefined,
  step1Description: undefined,
  step3Title: undefined,
}

Steps2.propTypes = {
  step2Title: PropTypes.element,
  step4Title: PropTypes.element,
  step4Description: PropTypes.element,
  step3Description: PropTypes.element,
  step1Title: PropTypes.element,
  step2Description: PropTypes.element,
  step1Description: PropTypes.element,
  step3Title: PropTypes.element,
}

export default Steps2
