import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './signup.css'
import {GoogleForms} from '../components/google-forms';

const SignUp = (props) => {
  return (
    <>
      <div className="signup-container1 thq-section-padding" id="signup">
        <div className="signup-max-width thq-section-max-width">
          <div className="signup-content1 thq-flex-row">
            <div className="signup-content2">
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="signup-text18 thq-heading-2">
                    Waiting list
                  </h2>
                </Fragment>
              )}
              {props.content1 ?? (
                <Fragment>
                  <p className="signup-text19 thq-body-large">
                    Sign up for the waiting list! This will give you the opportunity
                    to test our tool for free for at least a month.
                    You will also be the first to know when the web app is released!
                  </p>
                </Fragment>
              )}
            </div>
          </div>
          <div className="signup-content3-google">
            <GoogleForms formNr={1}/>
          </div>
        </div>
      </div>

      <div className="signup-container1 thq-section-padding" id="contributor">
        <div className="signup-max-width thq-section-max-width">
          <div className="signup-content1 thq-flex-row">
            <div className="signup-content2">
              <Fragment>
                <p className="signup-text19 thq-body-large">
                  If you would like to support the development of our application,
                  you can do so by answering a few questions below.
                  We would be very grateful if you filled out this survey.
                </p>
              </Fragment>
            </div>
          </div>
          <div className="signup-content3-google">
            <GoogleForms formNr={2}/>
          </div>
        </div>
      </div>
    </>
  )
}

SignUp.defaultProps = {
  heading1: undefined,
  content1: undefined,
}

SignUp.propTypes = {
  heading1: PropTypes.element,
  content1: PropTypes.element,
}

export default SignUp
