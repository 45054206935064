import React from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../blocks/navbar'
import Hero17 from '../blocks/hero17'
import CTA26 from '../blocks/cta26'
import Features5 from '../blocks/features5'
import Steps2 from '../blocks/steps2'
import SignUp from '../blocks/signup'
import Footer4 from '../blocks/footer4'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Tattoo Fit 3D</title>
        <meta property="og:title" content="Tattoo Fit 3D" />
      </Helmet>
      <Navbar rootClassName="navbar8root-class-name"></Navbar>
      <Hero17></Hero17>
      <Features5></Features5>
      <CTA26></CTA26>
      <Steps2></Steps2>
      <SignUp></SignUp>
      <Footer4></Footer4>
    </div>
  )
}

export default Home
