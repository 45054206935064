import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './hero17.css'

const Hero17 = (props) => {
  return (
    <div className="hero17-header78" id="home">
      <div className="hero17-column thq-section-max-width thq-section-padding">
        <div className="hero17-content">
          {props.heading1 ?? (
            <Fragment>
              <h1 className="hero17-text8 thq-heading-1">
                Bring Your Tattoos to Life in 3D!
              </h1>
            </Fragment>
          )}
          {props.content1 ?? (
            <Fragment>
              <p className="hero17-text5 thq-body-large">
                See how your tattoo designs will look on your body with our innovative web app.
                Using 3D technology and advanced male and female models, you can virtually
                see how your tattoos will appear on your skin before making a decision.
              </p>
            </Fragment>
          )}
        </div>
        <div className="hero17-actions">
          <a href="#signup" className="thq-button-filled hero17-button1">
            {props.action1 ?? (
              <Fragment>
                <span className="hero17-text6 thq-body-small">
                  Sign up now
                </span>
              </Fragment>
            )}
          </a>
        </div>
      </div>
      <div>
        <div className="hero17-container2">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero17.defaultProps = {
  content1: undefined,
  action1: undefined,
  action2: undefined,
  heading1: undefined,
}

Hero17.propTypes = {
  content1: PropTypes.element,
  action1: PropTypes.element,
  action2: PropTypes.element,
  heading1: PropTypes.element,
}

export default Hero17
